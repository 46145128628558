import React from "react";
import SEO from "components/seo";
import {
  ContactForm,
  ContentPhoto,
  FullPhoto,
  Header,
  Hero,
  Layout,
  Navigation,
  TextBox,
  WhatWeDid,
  StickyColumn,
} from "components/CaseLayout";

import { KLIMALAB } from "shared/Types/cases";
import { useCaseData } from "shared/Hooks/CasesNew/useCaseData";

const Klimalab = () => {
  const data = useCaseData(KLIMALAB);
  return (
    <Layout>
      <SEO title={data.title} description={data.seo} />
      <Hero data={data.hero} />
      <Navigation type={KLIMALAB} />
      <WhatWeDid data={data.what_we_did} />
      <Header data={data.headers.first} />
      <ContentPhoto data={data.content_images.first} />
      <TextBox data={data.text_boxes.first} />
      <ContentPhoto data={data.content_images.third} />
      <FullPhoto data={data.full_images.first} />
      <FullPhoto data={data.full_images.second} />
      <Header data={data.headers.second} />
      <StickyColumn data={data.sticky.first} />
      <StickyColumn reverse={1} data={data.sticky.second} />
      <FullPhoto data={data.full_images.third} />
      <ContactForm />
    </Layout>
  );
};

export default Klimalab;
